@import url(https://use.typekit.net/edg6agc.css);
body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.canvas {
  mix-blend-mode: multiply;
}

